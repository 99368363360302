<template>
  <div class="container">
    <h1 class="text-center text--blue">Brain Tease</h1>
    <h2 class="text-center">Challenge Your Mind with Fun Puzzles</h2>
    <p class="mt-4 text-center">
      Brain Tease is your go-to mobile puzzle app for testing your word-guessing
      skills. Dive into an engaging experience where you guess the word from
      cleverly crafted images, providing endless fun and mental stimulation.
    </p>
    <div class="row">
      <!-- First column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Engaging Puzzles:</li>
            <li>
              Enjoy a wide variety of puzzles that challenge your mind and keep
              you entertained as you guess the word from creative image hints.
            </li>
          </ul>
        </div>
      </div>

      <!-- Second column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Creative Wordplay:</li>
            <li>
              Each puzzle is uniquely designed with images that cleverly
              represent the word, making each level a new adventure in wordplay.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Third column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Fun for All Ages:</li>
            <li>
              Brain Tease offers puzzles that are perfect for players of all
              ages, making it a fun and educational experience for everyone.
            </li>
          </ul>
        </div>
      </div>

      <!-- Fourth column -->
      <div class="col-md-6">
        <div class="d-flex">
          <ul>
            <li>Progressive Difficulty:</li>
            <li>
              Start with easy puzzles and work your way up to more challenging
              ones as you improve your word-guessing skills.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex centered">
      <a class="app-button" href="https://apps.apple.com/app/6544787933">
        <img src="../assets/apple.png" alt="App Store" />
        <div class="text-container">
          <div>Get the App on</div>
          <div class="store__text">App Store</div>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }
}

h2 {
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
}

a {
  text-decoration: none !important;
}

.app-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #1f1f1f; /* Attractive dark color */
  transition: transform 1s ease-in-out;
  margin: 20px 0 40px 0;

  img {
    height: 35px;
    width: 35px;
  }

  .text-container {
    margin: 0 10px;

    div {
      color: white;
      font-size: small;
      text-decoration: none;
      text-align: center;
    }
    .store__text {
      font-size: medium;
      font-weight: 600;
    }
  }
}

.app-button:hover {
  transform: translateY(-5px);
}
</style>
